.PopupComponent {
  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    .display-preview {
      width: 100%;
      margin: 0;

      .preview {
        padding: 10px;
      }
    }
  }

  .PopupInputs {
    width: 100%;
    margin-top: 20px;

    &.customHTML textarea {
      height: 200px;
    }
  }
}

.WebInPageModal {
  width: 1500px !important;
}
